import React from 'react';

import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';
import {
    ExtensionLoginButton,
    WebWalletLoginButton,
    LedgerLoginButton,
    WalletConnectLoginButton
} from '@multiversx/sdk-dapp/UI';

import { useNavigate } from 'react-router-dom';

import App from 'assets/img/walletconnect/app.png';
import Extension from 'assets/img/walletconnect/extension.png';
import Ledger from 'assets/img/walletconnect/ledger.png';
import Web from 'assets/img/walletconnect/web.png';

import { routeNames } from 'routes';
import { walletConnectV2ProjectId } from 'config';

const Unlock = () => {
    const { account: {address: userAddress} } = useGetAccountInfo();
    const navigate = useNavigate();
    
    React.useEffect(() => {
        if(userAddress) {
            navigate(routeNames.home);
        }
    }, [userAddress]);

    const commonProps = {
      callbackRoute: routeNames.home,
    };

    return (
        <div className='unlock' style={{ marginTop: '100px' }}>
            <div className='container'>
                <div className='d-flex flex-column justify-content-center align-items-center'>
                    <div className='unlock-title'>Connect Wallet</div>

                    <ExtensionLoginButton
                        loginButtonText='Extension'
                        {...commonProps}
                    >
                        <img src={Extension} alt='maiar wallet extension' />
                        <span>MultiversX DeFi Wallet</span>
                        {/* <span className={styles.subtitle}>defi wallet</span> */}
                        <div className='flex-grow-1' />
                    </ExtensionLoginButton>

                    <WebWalletLoginButton
                        loginButtonText='Web wallet'
                        {...commonProps}
                    >
                        <img src={Web} alt='web wallet' />
                        <span>MultiversX Web Wallet</span>
                        {/* <span className={styles.subtitle}>web wallet</span> */}
                        <div className='flex-grow-1' />
                    </WebWalletLoginButton>

                    <LedgerLoginButton
                        loginButtonText='Ledger'
                        {...commonProps}
                    >
                        <img src={Ledger} alt='ledger' />
                        <span>Ledger</span>
                        <div className='flex-grow-1' />
                    </LedgerLoginButton>
                    
                    <WalletConnectLoginButton
                        loginButtonText='Maiar'
                        {...commonProps}
                        {...(walletConnectV2ProjectId
                            ? {
                                isWalletConnectV2: true
                              }
                            : {})}
                    >
                        <img src={App} alt='maiar app' style={{marginLeft: 3, height: 30, marginRight: 5}} />
                        <span>xPortal Mobile Wallet</span>
                        {/* <span className={styles.subtitle}>app</span> */}
                        <div className='flex-grow-1' />
                    </WalletConnectLoginButton>
                </div>
            </div>
        </div>
    );
};

export default Unlock;
