import React, { useState } from 'react';
import styles from './styles.module.scss';
import { BuyTokensModal } from './BuyTokensModal';
import './modalStyles.scss';
export const BuyTokensButton = () => {
  const [showBuyModal, setShowBuyModal] = useState(false);

  const showModal = () => {
    setShowBuyModal(true);
  };

  const hideModal = () => {
    console.log('Hide modal');
    setShowBuyModal(false);
  };

  return (
    <>
      <div className={`${styles.buyButton} d-flex justify-content-center`} onClick={showModal}>
        <div className='d-flex flex-column pl-1'>
          <div className={styles.text_title}>Credit Card</div>
        </div>
      </div>
      {showBuyModal && (
        <BuyTokensModal showModal={showBuyModal} hideModal={hideModal} />
      )}
    </>
  );
};
