import { AbiRegistry } from '@multiversx/sdk-core/out';
import privatesaleAbi from './abi/private-sale.abi.json';
import whitelistAbi from './abi/whitelist.abi.json';
import publicsaleAbi from './abi/onedex-public-sale-sc.abi.json';
import sftstakingAbi from './abi/onedex-sft-boost-staking.abi.json';

export const contractAddress =
  'erd1qqqqqqqqqqqqqpgq4gdcg0k83u7lpv4s4532w3au9y9h0vm70eqq6m8qk2';

export const dAppName = 'OneDex';
export const SOCIAL_WEBSITE_URL = 'https://synchronic.software/';
// export const WHITEPAPER_LINK = 'https://drive.google.com/file/d/107OCMm1TNQmsjFykr54L2kiJa0ekRAgf/view?usp=share_link';
export const WHITEPAPER_LINK = 'https://drive.google.com/file/d/1inf8EiPJFQj9Fv_16-sGxIKn4_milINa/view?usp=drive_link';
export const AUDIT_LINK = 'https://arda.run/audits/onedex';

export const isDev = false;

export const WhitelistAbi = AbiRegistry.create(whitelistAbi as any);
export const PrivateSaleAbi = AbiRegistry.create(privatesaleAbi as any);
export const PublicSaleAbi = AbiRegistry.create(publicsaleAbi as any);
export const SftStakingAbi = AbiRegistry.create(sftstakingAbi as any);

export const contracts = {
  'Whitelist': {
    address: isDev ? 'erd1qqqqqqqqqqqqqpgqamma0jj3zh02v2zrydvx5rhm0hp8tlsmkqys32za24' : 'erd1qqqqqqqqqqqqqpgql9m92mkvkrzg04wqakqnrmxqyj4kxrqgmvlscrnxvk',
    abi: WhitelistAbi,
  },
  'PrivateSale': {
    address: isDev ? 'erd1qqqqqqqqqqqqqpgqd2mnyzsfu4utmp0anguwj5mn98g3yx3skqysrtggxh' : 'erd1qqqqqqqqqqqqqpgqlvm3ntf9eyurenmdvl4celvlck7dn6gqmvlscg8r3m',
    abi: PrivateSaleAbi
  },
  'PublicSale': {
    address: isDev ? 'erd1qqqqqqqqqqqqqpgqrrepl5ysy35g8kkm496u0xmtdrn4l4wwkqys8380vh' : 'erd1qqqqqqqqqqqqqpgq49xdh8493ms6qnlc3svkx46vs528jjhsmvlsc2tuk5',
    abi: PublicSaleAbi
  },
  'SftStaking': {
    address: isDev ? 'erd1qqqqqqqqqqqqqpgql8xqkrm6fdnt7jyftwvsuj7m9yew0w0rkqysxsgdmp' : 'erd1qqqqqqqqqqqqqpgq8nlmvjm8gum6y2kqe0v296kgu8cm4jlemvlsays3ku',
    abi: SftStakingAbi
  }
};

export const adminList = [
  'erd14gmppcc30xshnv3wu6pt0s3mm8nnj782ra2cc4lkde8dftrukqysfu7kv9',
  'erd18ax6pkyful43knggqlv8afx22atthlyr7p3p4hjudzd42plkcv0qpumh4q',
  'erd1xkflzkx3hp52szy26zh9m5ts3v3j4dxhqkpxzj9npzp7wyp6qeysfpqz2m',
  'erd12lrknfza8hgkaa5ps4gl0fl8es6h23js47h7c76qftf7kt48mvlsvautgx'
];

export const ElrondApiUrl = isDev ? 'https://devnet-api.multiversx.com' : 'https://api.multiversx.com';

export const OneTokenId = isDev ? 'ONE-1873d4' : 'ONE-f9954f';
export const SftTokenId = isDev ? 'ONEDEX-1c8f60' : 'ONEDEX-df4fac';
export const UsdcTokenId = isDev ? 'USDC-ed97de' : 'USDC-c76f1f';
export const UsdtTokenId = isDev ? 'USDT-7fb037' : 'USDT-f8c08c';
export const BusdTokenId = isDev ? 'BUSD-0ee4f0' : 'BUSD-40b57e';

export const walletConnectV2ProjectId = '9f5121544fe51abd5781451f2f0928ce';