import React from 'react';
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import styles from './styles.module.scss';

export const BuyTokensModal = ({
  showModal,
  hideModal
}: {
  showModal: boolean;
  hideModal?: any;
}) => {
  return (
    <Modal className="buy_tokens_modal" show={showModal} onHide={hideModal}>
      <div className={styles.modal}>
        <Modal.Header>
          <div className='container'>
            <div className="d-flex fiat-top-menu">https://tradesilvania.com</div>
            <button className='d-flex close' onClick={hideModal}>×</button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className={styles.modal_content}>
            <iframe src='https://ramp.tradesilvania.com/?partnerId=65bcb6ffc9cb8dcfdd313286' allow='camera' allowFullScreen/>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
};
