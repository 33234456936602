import React from 'react';
import { AiFillDollarCircle } from 'react-icons/ai';
import { FaTwitter, FaTelegramPlane, FaMapSigns, FaEnvelope, FaYoutube, FaReddit, FaMedium } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import SiteLogo from 'assets/img/logo/site-logo.png';
import { routeNames } from 'routes';

const Footer = () => {
  return (
    <>
      <div className='container text-center' style={{ marginTop: '100px' }}>
        <span style={{ fontSize: '32px', fontWeight: '500', color: 'white' }}></span>
        <Link
            className='d-flex align-items-center navbar-brand mr-0'
            to={routeNames.home}
          >
            <img src={SiteLogo} width={220} alt="logo" style={{ width: 185, height: 35, margin: 'auto' }} />
        </Link>

        <div className='d-flex flex-column justify-content-center' style={{ marginTop: '15px', fontWeight: 300 }}>
          <div>
            OneDex Disclaimer
          </div>

          <div>
            Being the first permissionless DEX on MultiversX comes with multiple benefits for our users, but where there are benefits, there are also risks.
          </div>

          <div>
            Anyone can list a project, make sure you have DYOR before purchasing!
          </div>
        </div>

        <div className='d-flex justify-content-center' style={{ marginTop: '15px' }}>
          <div className='d-flex' style={{ gap: '1rem' }}>
            <a className='social-box' href="https://twitter.com/OneDex_X" target="blank">
              <FaTwitter />
            </a>
            <a className='social-box' href="https://t.me/one_dex_multiversx" target="blank">
              <FaTelegramPlane />
            </a>
            <a className='social-box' href="https://explorer.elrond.com/tokens/ONE-f9954f" target="blank">
              <AiFillDollarCircle /> 
            </a>
            <a className='social-box' href="https://linktr.ee/onedex" target="blank">
              <FaMapSigns />
            </a>
            <a className='social-box' href="https://youtube.com/@OneDex_X" target="blank">
              <FaYoutube />
            </a>
            <a className='social-box' href="https://www.reddit.com/r/OneDex" target="blank">
              <FaReddit />
            </a>
            <a className='social-box' href="https://medium.com/@onedexapp" target="blank">
              <FaMedium />
            </a>
            <a className='social-box' href="mailto:contact@onedex.app" target="blank">
              <FaEnvelope />
            </a>
          </div>
        </div>

        <div style={{ marginTop: '15px' }}>
          <p>
            Made With ❤️ By {' '} OneDEX Team
          </p>
        </div>
      </div>
    </>
  );
};

export default Footer;
