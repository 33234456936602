import * as React from 'react';

import homeLaunchApp from 'assets/img/home-launch-app.png';
import { formatNumberToLocalString } from 'utils';

import SwapCard from 'assets/img/functional-cards/Swap.png';
import EarnCard from 'assets/img/functional-cards/Earn.png';
import FarmCard from 'assets/img/functional-cards/Farms.png';
import LaunchpadCard from 'assets/img/functional-cards/Launchpad.png';
import TokenAllocation from 'assets/img/token-allocation/pie.png';
import RoadMap from 'assets/img/roadmap/roadmap.png';

import MetadataDex from "../../components/Metadata";

const tokenomics = {
  totalSupply: 300000000,
  distributions: [60000000, 15000000, 30000000, 30000000, 9000000, 45000000, 24000000, 9000000, 30000000, 48000000],
  labels: ['Public Sale', 'Treasury', 'Private Sale', 'Team', 'Advisors', 'Liquidity', 'Stake Rewards', 'Marketing', 'Farm Rewards', 'Future Listing']
};

const options: any = {
  series: tokenomics.distributions,
  chart: {
    type: 'donut',
    background: '0'
  },
  labels: tokenomics.labels,
  fill: {
    type: 'gradient',
  },
  stroke: {
    show: false,
  },
  theme: {
    mode: 'dark',
    monochrome: {
      enabled: true,
      color: '#FFAA12',
      shadeTo: 'dark'
    }
  },
  plotOptions: {
    pie: {
      dataLabels: {
        enabled: false,
        offset: -5
      }
    }
  },
  legend: {
    show: false
  },
  responsive: [
    {
      breakpoint: 480,
      options: {
        chart: {
          width: 350,
        },
      },
    },
  ],
  tooltip: {
    // x: {
    //   formatter: (item: any) => formatNumberToLocalString(item),
    // },
    y: {
      formatter: (item: any) => formatNumberToLocalString(item),
    },
    // z: {
    //   formatter: (item: any) => formatNumberToLocalString(item),
    // },
  },
};

const faqs = [
  {
    question: "What are decentralized exchanges?",
    answer: "Decentralized exchanges, also known as DEXs, are peer-to-peer marketplaces where cryptocurrency traders make transactions directly without handing over the management of their funds to an intermediary or custodian. These transactions are facilitated through the use of self-executing agreements written in code called smart contracts."
  },
  {
    question: "How do DEXs work?",
    answer: "As OneDex is built on top of the MultiversX blockchain that supports smart contracts and where users keep custody of their funds, every trade incurs a transaction fee along with the trading fee. In essence, traders interact with smart contracts on the blockchain to use OneDex."
  },
  {
    question: "How many types of DEXs are?",
    answer: "There are three main types of decentralized exchanges: Automated market makers, Order books DEXs and DEX aggregators. All of them allow users to trade directly with each other through their smart contracts. The first decentralized exchanges used the same type of order books, similar to centralized exchanges."
  },
  {
    question: "What type of dex is OneDex?",
    answer: `OneDex is an automated market maker (AMM) system that relies on smart contracts .
    OneDex relies on blockchain-based services that provide information from exchanges and other platforms to set the price of traded assets called blockchain oracles. Instead of matching buy orders and sell orders, the smart contracts of onedex use pre-funded pools of assets known as liquidity pools. 
    The pools are funded by other users who are then entitled to the transaction fees that the protocol charges for executing trades on that pair. These liquidity providers need to deposit an equivalent value of each asset in the trading pair to earn interest on their cryptocurrency holdings, a process known as liquidity mining. If they attempt to deposit more of one asset than the other, the smart contract behind the pool invalidates the transaction.
    The use of liquidity pools allows traders to execute orders or to earn interest in a permissionless and trustless way. These exchanges are often ranked according to the amount of funds locked in their smart contracts called total value locked (TVL), as the AMM model has a downside when there is not enough liquidity: slippage.
    Slippage occurs when a lack of liquidity on the platform results in the buyer paying above-market prices on their order, with larger orders facing higher slippage. A lack of liquidity can deter wealthy traders from using these platforms, as large orders are likely to suffer from slippage without deep liquidity.
    Liquidity providers also face various risks including impermanent loss, which is a direct result of depositing two assets for a specific trading pair. When one of these assets is more volatile than the other, trades on the exchange can lower the amount of one asset in the liquidity pool.
    If the highly volatile asset's price rises while the amount liquidity providers hold drops, liquidity providers suffer an impermanent loss. The loss is impermanent because the price of the asset can still move back up and trades on the exchange can balance the pair’s ratio. The pair’s ratio describes the proportion of each asset held in the liquidity pool. Furthermore, fees collected from trading can make up for the loss over time.`,
  },
  {
    question: "How to use OneDex?",
    answer: "Using OneDex does not involve a sign-up process, as you do not even need an email address to interact with these platforms. Instead, traders will need a wallet compatible with smart contracts (MultiversX). Anyone with a smartphone and an internet connection can benefit from the financial services offered by DEXs."
  },
  {
    question: "What are the advantages of using OneDex?",
    answer: "Trading on OneDex is not expensive, especially since OneDex is built on the MultiversX network."
  },
  {
    question: "Will I remain anonymous using OneDex?",
    answer: "When users exchange one cryptocurrency for another, their anonymity is preserved on OneDex. In contrast to centralized exchanges, users do not need to go through a standard identification process known as Know Your Customer (KYC). KYC processes involve collecting traders’ personal information, including their full legal name and a photograph of their government-issued identification document. As a result, DEXs attract a large number of people who do not wish to be identified."
  },
  {
    question: "Who can be listed on OneDex?",
    answer: "If you are the owner of a project built on MultiversX, you can list it on OneDex, without the approval of the Onedex team"
  },
  {
    question: "How can a project open a stake smart contract on OneDex?",
    answer: "If you are the owner of a project built on MultiversX  you can open a stake smart contract for your project"
  },
  {
    question: "How can a project open an LP farm?",
    answer: "If you are the owner of a project built on MultiversX you can open an LP farm for your project"
  },
  {
    question: "How can a project be launched on OneDex Launchpad?",
    answer: "If you are the owner of a project built on MultiversX you can have seed, private and public sale"
  }
];


const Home = () => {

  return (
    <div className='home'>
      <div className='home-section'>
        <div className='container'>
          <MetadataDex />
          <div className='d-flex flex-column justify-content-center align-items-center'>
            <div className='home-title'>
              Decentralized Exchange
            </div>
            <div className='home-title-1'>
              Made for Everybody
            </div>
            <span className='mt-4 home-subtitle'>Owned By No One, Owned By Everyone</span>
            {/* <div className='home-coin-logo mt-4'>
              <img src={coinLogo} />
            </div> */}
            <a href='https://swap.onedex.app' target="_blank" rel="noreferrer" className='home-launch-app'>
              <img src={homeLaunchApp} />
            </a>
          </div>
        </div>
      </div>

      <div className='home-section-2'>
        <div className='container'>

          <div className='row' style={{ rowGap: '20px' }}>
            <div className='col-6 col-sm-3'>
              <img src={SwapCard} style={{ width: '100%', cursor: 'pointer' }} />
            </div>

            <div className='col-6 col-sm-3'>
              <img src={EarnCard} style={{ width: '100%', cursor: 'pointer' }} />
            </div>

            <div className='col-6 col-sm-3'>
              <img src={FarmCard} style={{ width: '100%', cursor: 'pointer' }} />
            </div>

            <div className='col-6 col-sm-3'>
              <img src={LaunchpadCard} style={{ width: '100%', cursor: 'pointer' }} />
            </div>
          </div>

          <div className='d-flex flex-column justify-content-center align-items-center home-info' style={{ rowGap: '15px', paddingTop: '100px' }} id="token">
            <div className='home-token-distribution' id="token">
              Token Distribution
            </div>
            <div className="">
              <img src={TokenAllocation} />
            </div>

            {/* <div className="roadmap">
              <div>
                <img src={Q20224} style={{ width: '100%', cursor: 'pointer' }} />
              </div>

              <div>
                <img src={Q20231} style={{ width: '100%', cursor: 'pointer' }} />
              </div>

              <div>
                <img src={Q20232} style={{ width: '100%', cursor: 'pointer' }} />
              </div>

              <div>
                <img src={Q20233} style={{ width: '100%', cursor: 'pointer' }} />
              </div>

              <div>
                <img src={Q20234} style={{ width: '100%', cursor: 'pointer' }} />
              </div>
            </div> */}

            {/* <div className='home-title'>
              FAQ
            </div>

            <div className="custom-accordion" style={{ width: '100%' }}>
              <Accordion flush>
                {
                  faqs.map((row, index) => {
                    return (
                      <Accordion.Item eventKey={index.toString()} key={index}>
                        <Accordion.Header>{index + 1}. {row.question} </Accordion.Header>
                        <Accordion.Body>
                          {row.answer}
                        </Accordion.Body>
                      </Accordion.Item>
                    );
                  })
                }
              </Accordion>
            </div> */}
          </div>


        </div>
      </div>

      <div className='home-section-3'>
        <div className='home-token-distribution' id="roadmap">
          Roadmap
        </div>
        <div className='home-roadmap'>
          <div className='d-flex justify-content-end' style={{ position: 'absolute', width: '100%', height: '50vh', textAlign: 'right' }}>
            <div className='home-roadmap-gradiant'></div>
          </div>
          <div className='container'>
            <div className='d-flex flex-column justify-content-center align-items-center home-info' >
              <div>
                <img src={RoadMap} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
