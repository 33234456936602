import * as React from 'react';

const ComingSoon = () => {
  return (
    <div className='d-flex flex-fill align-items-center container justify-content-center' style={{ minHeight: '85vh' }}>
      <div className="text-center color-primary" style={{ fontSize: '70px', fontWeight: '600'}}>Coming Soon</div>
    </div>
  );
};

export default ComingSoon;
