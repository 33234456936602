import React, { useEffect, useState } from "react";

import S from './style.module.scss';
import axios from "axios";
import { BigZero, formatNumberToLocalString } from "../../utils";

type TokenSupply = {
    supply: string;
    circulatingSupply: string;
    burnt: string;
    initialMinted: string;
}

type TotalVolume = {total_tvl: string; total_volume: string;}

type TokenAnalyticType = {
    token_id: string;
    price: string;
    price_change: string;
    price_change_percentage: string;
    volume: string;
    liquidity: string;
}

const MetadataDex = () => {

    const [totalSupply, setTotalSupply] = useState<string>('0');
    const [totalTradingVolume, setTotalTradingVolume] = useState<string>('0');
    const [dailyVolume, setDailyVolume] = useState<string>('0');
    const [totalTvl, setTotalTvl] = useState<string>('0');

    useEffect(() => {
        (async () => {
            try {
                const { data } = await axios.get<TokenSupply>(`https://api.elrond.com/tokens/ONE-f9954f/supply`);
                setTotalSupply(formatNumberToLocalString(Number(data.supply)));
            } catch (error) {
                console.log(error);
            }
        })();

        fetchTradingData();
        fetchTokens();
    }, []);

    const fetchTradingData = async () => {
        try {
            const { data } = await axios.get<TotalVolume>(`https://api.onedex.app/metrics`);
            setTotalTradingVolume(formatNumberToLocalString(Number(data.total_volume)));
            setTotalTvl(formatNumberToLocalString(Number(data.total_tvl)))
        } catch (error) {
            // Handle error if needed
            console.error('Error fetching metrics:', error);
        }
    };

    const fetchTokens = async () => {
        try {
            const { data } = await axios.get<TokenAnalyticType[]>(`https://api.onedex.app/tokens`);
            let volume = BigZero;

            for (const token of data) {
                volume = volume.plus(token.volume);
            }

            setDailyVolume(formatNumberToLocalString(Number(volume)));
        } catch (error: any) {
            console.log(error);
        }
    }


    return (
        <div className={`row p-3 mb-5 d-flex justify-content-center`}>
            <div className={`d-flex flex-column justify-content-center align-items-center col-5 col-md-2 p-3 ${S.cardMetadata}`}>
                <div style={{ fontWeight: "bolder" }} className="text-muted">
                    Total Supply
                </div>
                <div>{totalSupply}</div>
            </div>

            <div className={`d-flex flex-column justify-content-center align-items-center col-5 col-md-2 p-3 ${S.cardMetadata}`}>
                <div style={{ fontWeight: "bolder" }} className="text-muted">
                    Total Liquidity
                </div>
                <div>${totalTvl}</div>
            </div>

            <div className={`d-flex flex-column justify-content-center align-items-center col-5 col-md-2 p-3 ${S.cardMetadata}`}>
                <div style={{ fontWeight: "bolder" }} className="text-muted">
                    Daily Volume
                </div>
                <div>${dailyVolume}</div>
            </div>

            <div className={`d-flex flex-column justify-content-center align-items-center col-5 col-md-2 p-3 ${S.cardMetadata}`}>
                <div style={{ fontWeight: "bolder" }} className="text-muted">
                    Total Volume
                </div>
                <div>${totalTradingVolume}</div>
            </div>
        </div>
    );
};

export default MetadataDex;
