import BigNumber from 'bignumber.js/bignumber';

export function getCurrentTimestamp() {
    return (new Date()).getTime();
}

export const paddingTwoDigits = (num: any) => {
    return num.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
    });
};

export const floorNumber = (raw: number, precision?: number) => {
    const x = Math.pow(10, precision || 2);
    return Math.floor(raw * x) / x;
};

export const formatNumberToLocalString = (value: number, precision?: number): string => {
    return floorNumber(value, precision ? precision : 4).toLocaleString();
};

export const BigZero = new BigNumber(0);

export const convertWeiToEsdt = (v: any, decimals = 18): BigNumber => {
    let value;

    if (typeof (v) !== typeof (BigNumber)) {
        value = new BigNumber(v);
    } else {
        value = v;
    }

    const result = value.dividedBy(new BigNumber(10 ** decimals));

    return result;
};

export const convertEsdtToWei = (v: any, decimals = 18): BigNumber => {
    let value;

    if (typeof (v) !== typeof (BigNumber)) {
        value = new BigNumber(v);
    } else {
        value = v;
    }

    const result = value.multipliedBy(new BigNumber(10 ** decimals)).decimalPlaces(0, BigNumber.ROUND_FLOOR);

    return result;
};

export const shortenEsdt = (v: any, precision = 2): string => {
    let value;

    if (typeof (v) !== typeof (BigNumber)) {
        value = new BigNumber(v);
    } else {
        value = v;
    }

    const number = +value.toFixed(precision, BigNumber.ROUND_FLOOR);

    return new Intl.NumberFormat('en-US', { minimumFractionDigits: 0, maximumFractionDigits: precision }).format(number);
};

export const shortenAddress = (address: string) => {
    if(address.length) {
          return address.substring(0, 10) + '...' + address.substring(address.length - 10);
    } else {
        return '';
    }
};